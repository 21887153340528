import classnames from 'classnames';
import { Component, Fragment, h } from 'preact';

import InputWrapper from '../InputWrapper';

import * as styles from './styles.css';

export class InputTokenizer extends Component {
  static defaultProps = {
    onBlur: () => null,
    onFocus: () => null,
    onInput: () => null,
    onInvalid: () => null,
    onReady: () => null,
    onResetTokens: () => null,
    onTokenizationFail: () => null,
    onTokenizationFulfill: () => null,
    onTokenizationStart: () => null,
    onTokenizationSuccess: () => null,
    onValid: () => null,
  };

  constructor(props) {
    super(props);

    this.handleReceiveMessage = this.handleReceiveMessage.bind(this);
  }

  state = {
    validationError: undefined,
    isFocused: false,
    tokens: [],
  };

  componentDidMount() {
    window.addEventListener('message', this.handleReceiveMessage);
  }

  componentDidUpdate(_, { validationError: prevValidationError }) {
    const { onInvalid, onValid } = this.props;
    const { validationError } = this.state;

    if (validationError === prevValidationError) {
      return;
    }

    if (validationError !== undefined) {
      onInvalid(validationError);
    } else {
      onValid();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleReceiveMessage);
  }

  handleReceiveMessage(event) {
    const {
      extendedInfo,
      frameName: currentFrame,

      onBlur,
      onFocus,
      onInput,
      onReady,
      onResetTokens,
      onTokenizationFail,
      onTokenizationFulfill,
      onTokenizationStart,
      onTokenizationSuccess,
    } = this.props;
    const {
      data: { meta: { action, frameName } = {}, payload = {} },
    } = event;

    if (frameName !== currentFrame) {
      return;
    }

    switch (action) {
      case 'TOKENIZATION_FORM_IS_READY': {
        onReady();
        break;
      }

      case 'FIELD_WAS_FOCUSED': {
        this.setState(
          () => ({ isFocused: true }),
          () => onFocus(),
        );
        break;
      }

      case 'FIELD_WAS_BLURRED': {
        const { validationError } = payload;

        this.setState(
          () => ({
            validationError,
            isFocused: false,
          }),
          () => onBlur(validationError),
        );
        break;
      }

      case 'FIELD_WAS_CHANGED': {
        const { validationError } = payload;

        this.setState(
          () => ({ validationError }),
          () => onInput(validationError),
        );
        break;
      }

      case 'TOKEN_WAS_REQUESTED': {
        onTokenizationStart();
        break;
      }

      case 'TOKEN_WAS_RECEIVED': {
        const { tokens } = payload;

        this.setState(
          () => ({
            tokens: Object.entries(tokens).map(([name, value]) => ({
              name,
              value,
            })),
          }),
          () => {
            const normalizedTokens = extendedInfo
              ? tokens
              : Object.entries(tokens).reduce(
                  (result, [name, { token }]) => ({
                    ...result,
                    [name]: token,
                  }),
                  {},
                );

            onTokenizationSuccess(normalizedTokens);
            onTokenizationFulfill();
          },
        );
        break;
      }

      case 'TOKEN_WAS_NOT_RECEIVED': {
        const { error } = payload;

        onTokenizationFail(error);
        onTokenizationFulfill();
        break;
      }

      case 'TOKEN_WAS_RESETED': {
        this.setState(
          () => ({ tokens: [] }),
          () => onResetTokens(),
        );
        break;
      }

      default:
        break;
    }
  }

  renderComponent({
    class: className,
    extendedInfo,
    frameName,
    frameUrl,
    title,
    name,
    tokens,
  }) {
    return (
      <Fragment>
        <iframe
          className={classnames(className, styles.frame)}
          name={frameName}
          src={frameUrl}
          title={title}
        />

        {tokens.map(({ name: tokenName, value: { info, token } }) => {
          const inputName = name ? `${name}.${tokenName}` : tokenName;

          return (
            <Fragment key={tokenName}>
              <input name={inputName} type="hidden" value={token} />

              {extendedInfo && typeof info === 'object' && info !== null
                ? Object.entries(info).map(([paramName, paramValue]) => {
                    const infoParamName = `${inputName}.${paramName}`;

                    return (
                      <input
                        key={infoParamName}
                        name={infoParamName}
                        type="hidden"
                        value={paramValue}
                      />
                    );
                  })
                : null}
            </Fragment>
          );
        })}
      </Fragment>
    );
  }

  render() {
    const { extendedInfo, frameName, frameUrl, name, title } = this.props;
    const { isFocused, tokens, validationError } = this.state;
    const hasError = Boolean(validationError);

    return (
      <InputWrapper
        classNames={{
          container: styles.container,
          deco: styles.deco,
          field: styles.frame,
          isFocused: styles['container_isFocused'],
          isInvalid: styles['container_isInvalid'],
        }}
        component={this.renderComponent}
        extendedInfo={extendedInfo}
        frameName={frameName}
        frameUrl={frameUrl}
        isFocused={isFocused}
        isInvalid={hasError}
        name={name}
        title={title}
        tokens={tokens}
      />
    );
  }
}
