import { h, render } from 'preact';

import { InputTokenizer } from '~/components/InputTokenizer';
import WidgetError from '~/utils/errors';
import mergeObjects from '~/utils/mergeObjects';
import { encode } from '~/utils/urlParams';

let counter = 0;

const STATUSES_MAPPING = [
  {
    long: 'base',
    short: 'b',
    children: [
      { long: 'background-color', short: 'bc' },
      { long: 'padding', short: 'p' },
      { long: 'padding-bottom', short: 'pb' },
      { long: 'padding-left', short: 'pl' },
      { long: 'padding-right', short: 'pr' },
      { long: 'padding-top', short: 'pt' },
    ],
  },
  {
    long: 'placeholder',
    short: 'p',
    children: [
      { long: 'color', short: 'c' },
      { long: 'font', short: 'f' },
      { long: 'font-size', short: 'fs' },
      { long: 'font-weight', short: 'fw' },
    ],
  },
  {
    long: 'value',
    short: 'v',
    children: [
      { long: 'color', short: 'c' },
      { long: 'font', short: 'f' },
      { long: 'font-size', short: 'fs' },
      { long: 'font-weight', short: 'fw' },
      { long: 'line-height', short: 'lh' },
    ],
  },
];
const PARAMS_MAPPING = [
  { long: 'autoFocus', short: 'af' },
  { long: 'placeholder', short: 'ph' },
  { long: 'publicToken', short: 'pt' },
  {
    long: 'styles',
    short: 's',
    children: [
      {
        long: 'normal',
        short: 'n',
        children: STATUSES_MAPPING,
      },
      {
        long: 'focused',
        short: 'f',
        children: STATUSES_MAPPING,
      },
      {
        long: 'invalid',
        short: 'i',
        children: STATUSES_MAPPING,
      },
      {
        long: 'invalidFocused',
        short: 'if',
        children: STATUSES_MAPPING,
      },
    ],
  },
];

export default class Element {
  static DEFAULT_OPTIONS = {
    autoFocus: false,
    frameTitle: undefined,
    placeholder: undefined,
  };

  static DEFAULT_CONTAINER_SELECTOR;
  static FRAME_BASE_URL;
  static PARAMS_MAPPING = PARAMS_MAPPING;
  static TYPE;

  constructor(publicToken, { name, ...options } = {}) {
    this.publicToken = publicToken;

    this.name = name;
    this.options = mergeObjects(
      this.constructor.DEFAULT_OPTIONS,
      {
        container: document.querySelector(
          this.constructor.DEFAULT_CONTAINER_SELECTOR,
        ),
      },
      options,
    );
  }

  isRendered = false;

  get frameName() {
    const frameName = `${this.constructor.TYPE}::${counter}`;

    counter += 1;

    return frameName;
  }

  generateFrameUrl(options = {}) {
    const encodedUrlParams = encode(
      {
        ...options,
        publicToken: this.publicToken,
      },
      this.constructor.PARAMS_MAPPING,
    );

    if (encodedUrlParams !== '') {
      return `${this.constructor.FRAME_BASE_URL}?${encodedUrlParams}`;
    }

    return this.constructor.FRAME_BASE_URL;
  }

  onReady = () => null;

  onDestroy = () => null;

  /**
   * @param {string} [validationError] - Code of the field validation error.
   */
  onBlur = () => null;

  onFocus = () => null;

  /**
   * @param {string} [validationError] - Code of the field validation error.
   */
  onInput = () => null;

  /**
   * @param {string} [validationError] - Code of the field validation error.
   */
  onInvalid = () => null;

  onValid = () => null;

  onResetTokens = () => null;

  /**
   * @param {string} error - Error code of the tokenization process.
   */
  onTokenizationFail = () => null;

  onTokenizationFulfill = () => null;

  onTokenizationStart = () => null;

  /**
   * @param {Object.<string, string>} tokens - Object of tokenized data.
   */
  onTokenizationSuccess = () => null;

  render(options = {}) {
    const resultOptions = mergeObjects(this.options, options);
    const { container, extendedInfo, frameTitle } = resultOptions;

    if (!container) {
      throw new WidgetError(
        `You should specify a correct container to render into it the widget "${this.constructor.name}".`,
      );
    }

    container.innerHTML = '';

    render(
      <InputTokenizer
        extendedInfo={extendedInfo}
        frameName={this.frameName}
        frameUrl={this.generateFrameUrl(resultOptions)}
        name={this.name}
        publicToken={this.publicToken}
        title={frameTitle}
        onReady={() => {
          this.isRendered = true;
          this.onReady();
        }}
        onBlur={(validationError) => this.onBlur(validationError)}
        onFocus={() => this.onFocus()}
        onInput={(validationError) => this.onInput(validationError)}
        onInvalid={(validationError) => this.onInvalid(validationError)}
        onValid={() => this.onValid()}
        onResetTokens={() => this.onResetTokens()}
        onTokenizationFail={(error) => this.onTokenizationFail(error)}
        onTokenizationFulfill={() => this.onTokenizationFulfill()}
        onTokenizationStart={() => this.onTokenizationStart()}
        onTokenizationSuccess={(tokens) => this.onTokenizationSuccess(tokens)}
      />,
      container,
    );

    return this;
  }

  destroy() {
    if (this.isRendered) {
      const { container } = this.options;

      container.innerHTML = '';
      this.isRendered = false;
      this.onDestroy();
    }
  }
}
