/**
 * @deprecated
 * @see src/components/InputBase/index.js
 */
import classnames from 'classnames';
import { Component, h } from 'preact';

import * as styles from './styles.css';

export default class InputWrapper extends Component {
  static defaultProps = {
    classNames: {},
    component: 'input',
    isInvalid: false,
    withDeco: true,

    onBlur: () => null,
    onFocus: () => null,
  };

  static getDerivedStateFromProps({ isFocused }) {
    if (isFocused !== undefined) {
      return { isFocused };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  state = {
    isFocused: false,
  };

  handleBlur(...args) {
    const { onBlur } = this.props;

    this.setState(
      () => ({ isFocused: false }),
      () => onBlur(...args),
    );
  }

  handleFocus(...args) {
    const { onFocus } = this.props;

    this.setState(
      () => ({ isFocused: true }),
      () => onFocus(...args),
    );
  }

  render() {
    const {
      class: className,
      classNames,
      component: InputComponent,
      disabled,
      isInvalid,
      withDeco,
      ...props
    } = this.props;
    const { isFocused } = this.state;

    return (
      <span
        className={classnames(
          styles.container,
          classNames.container,
          className, // @todo Should be remove.
          disabled ? styles.isDisabled : null,
          ...(isFocused ? [styles.isFocused, classNames.isFocused] : [null]),
          ...(isInvalid ? [styles.isInvalid, classNames.isInvalid] : [null]),
        )}
      >
        <InputComponent
          {...props}
          class={classnames(styles.field, classNames.field)}
          disabled={disabled}
          isInvalid={isInvalid}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
        />

        {withDeco ? (
          <span className={classnames(styles.background, classNames.deco)} />
        ) : null}
      </span>
    );
  }
}
