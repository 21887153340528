// @deprecated
export default class WidgetError extends Error {
  constructor(message, payload = {}) {
    super(message);

    this.name = this.constructor.name;
    this.message = message;
    this.payload = payload;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error().stack;
    }
  }
}

export class CustomError extends Error {
  constructor(message) {
    super(message);

    this.name = this.constructor.name;
    this.message = message;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error().stack;
    }
  }
}

export class DevError extends CustomError {
  constructor(message) {
    super(message);
  }
}

export class AuthError extends CustomError {
  static CODES = {
    expiredToken: 'EXPIRED_TOKEN',
    invalidToken: 'INVALID_TOKEN',
  };

  constructor(code) {
    super(`Authentication error: ${code}.`);

    this.code = code;
  }
}

export class HttpError extends CustomError {
  constructor(statusCode = 500, message = 'Something went wrong...') {
    super(message);

    this.statusCode = statusCode;
  }
}
