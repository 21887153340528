export const ROUTES = {
  bankAccount: '/bank-account',

  demo3DSecure: '/demo-3-d-secure',

  selfEmployed: '/self-employed',

  session: '/session/:hashId/:sign',

  // @deprecated
  tokenizeCard: '/tokenize-card',
};
