import mergeObjects from '../mergeObjects';

const encodeAbstractLevel = (object, prefix) => {
  return Object.entries(object).reduce((result, [name, value]) => {
    const restParams = result !== '' ? `${result}&` : '';
    const nextPrefix = `${prefix}.${name}`;
    const nextValue = encodeURIComponent(String(value));

    return `${restParams}${nextPrefix}=${nextValue}`;
  }, '');
};

const encodeLevel = (mappingLevel, object, prefix = '') =>
  mappingLevel.reduce((result, { children, long, short, variants = [] }) => {
    const value = [long, ...variants].reduce((foundValue, longName) => {
      return foundValue ?? object[longName];
    }, undefined);

    if ([false, null, undefined].includes(value)) {
      return result;
    }

    const restParams = result !== '' ? `${result}&` : '';
    const nextPrefix = prefix !== '' ? `${prefix}.${short}` : short;

    if (children === 'all') {
      return `${restParams}${encodeAbstractLevel(value, nextPrefix)}`;
    }

    if (Array.isArray(children)) {
      return `${restParams}${encodeLevel(children, value, nextPrefix)}`;
    }

    const nextValue = encodeURIComponent(value);

    return `${restParams}${nextPrefix}=${nextValue}`;
  }, '');

/**
 * @typedef Mapper
 * @type {Array.<{{ long: string, short: string, ?children: Mapper }}>}
 */

/**
 * Encodes an object to the URI string with shorter names.
 *
 * @param {Object} — Source object.
 * @param {Mapper} [mapping=PARAMS_MAPPING] — Mapper.
 * @returns {string}
 */
export const encode = (object, mapping) => encodeLevel(mapping, object);

const getAbstractBranchByPath = (path, value) => {
  const [name] = path;

  return {
    [name]: decodeURIComponent(value),
  };
};

const getBranchByPath = (mapping, path, value) => {
  const [shortParamName, ...nextPath] = path;
  const { children, long } =
    mapping.find(({ short }) => short === shortParamName) || {};

  if (long === undefined) {
    return undefined;
  }

  if (children) {
    const nextLevel =
      children === 'all'
        ? getAbstractBranchByPath(nextPath, value)
        : getBranchByPath(children, nextPath, value);

    if (nextLevel === undefined) {
      return undefined;
    }

    return {
      [long]: nextLevel,
    };
  }

  return {
    [long]: decodeURIComponent(value),
  };
};

/**
 * Decodes URI string to the object.
 *
 * @param {string} — Source string.
 * @param {Mapper} [mapping=PARAMS_MAPPING] — Mapper.
 * @returns {Object}
 */
export const decode = (string, mapping) =>
  string.split('&').reduce((result, item) => {
    const [paramPath, value] = item.split('=');
    const paramPathSplit = paramPath.split('.');
    const branch = getBranchByPath(mapping, paramPathSplit, value);

    if (branch === undefined) {
      return result;
    }

    return mergeObjects(result, branch);
  }, {});
