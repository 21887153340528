import WidgetError from '~/utils/errors';

import ElementCardNumber from './ElementCardNumber';

const ELEMENT_CONSTRUCTORS = [ElementCardNumber];

export default class Elements {
  static TYPES = ELEMENT_CONSTRUCTORS.reduce(
    (result, { TYPE: type }) => ({
      ...result,
      [type]: type,
    }),
    {},
  );

  constructor(publicToken) {
    this.publicToken = publicToken;
  }

  create(type, options = {}) {
    const Constructor = ELEMENT_CONSTRUCTORS.find(({ TYPE }) => TYPE === type);

    if (!Constructor) {
      const knownTypes = ELEMENT_CONSTRUCTORS.map(
        ({ TYPE }) => `${process.env.PREFIX_CAPITALIZED}Elements.TYPES.${TYPE}`,
      ).join(', ');

      throw new WidgetError(
        `Unknown type "${type}" of ${process.env.PREFIX_CAPITALIZED}Elements. Use one of the next: ${knownTypes}.`,
      );
    }

    return new Constructor(this.publicToken, options);
  }
}
