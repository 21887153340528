import { ROUTES } from '~/config';

import Element from './Element';

export default class ElementCardNumber extends Element {
  static DEFAULT_CONTAINER_SELECTOR = `#${process.env.PREFIX}-element-card-number`;
  static DEFAULT_OPTIONS = {
    ...Element.DEFAULT_OPTIONS,
    extendedInfo: false,
    frameTitle: 'Card number input field',
  };
  static FRAME_BASE_URL = `${process.env.APP_BASE_URL}${ROUTES.tokenizeCard}`;
  static TYPE = 'cardNumber';
}
