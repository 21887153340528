/**
 * Deep merges two and more objects.
 *
 * @param  {...any} objects - Objects for merge.
 * @returns {Object}
 */
const mergeObjects = (...objects) => {
  return objects.reduce((result, object) => {
    if (!object) {
      return result;
    }

    for (const [key, value] of Object.entries(object)) {
      const oValue = result[key];

      if (Array.isArray(value) && Array.isArray(oValue)) {
        result[key] = [...oValue, ...value];
      } else if (
        typeof value === 'object' &&
        value !== null &&
        typeof oValue === 'object' &&
        oValue !== null
      ) {
        result[key] = mergeObjects(oValue, value);
      } else {
        result[key] = value;
      }
    }

    return result;
  }, {});
};

export default mergeObjects;
